import { EventEmitter } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { DocumentPipe } from "../shared/pipes/document.pipe";

export class AutoCompleteUtilities {

    static displayName(object: any) {
        return object && object.name ? object.name : '';
    }

    static displayDescription(object: any) {
        return object && object.description ? object.description : '';
    }

    static displayCode(object: any) {
        return object && object.code ? object.code : "";
    }
    
    static displayCodeDescription(object: any) {
        return object ? `${object.code || ''} - ${object.description || ''}` : "";
    }


    static displayDocument(person: any): string {
        return person && person.document ? new DocumentPipe().transform(person.document) : '';
    }

    /** Clear input if the value is a simple string */
    static clearSimple(control: AbstractControl, emitter?: EventEmitter<any>) {
        setTimeout(() => {
            if (typeof control.value === 'string') {
                control.setValue("");
                if(emitter){
                    emitter.emit(null);
                }
            }
            control.updateValueAndValidity();
        }, 500);
    }

    static clearSimpleInput(input: HTMLInputElement, ngModelValue: any){
        setTimeout(() => {
            if(typeof ngModelValue === "string"){
                input.value = "";
                ngModelValue = undefined
            }
        }, 500);
    }

}